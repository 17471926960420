import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Services } from './components/Services';
import { Gallery } from './components/Gallery';
//import { About } from './components/About';
//import { FAQ } from './components/FAQ';
import { Contact } from './components/Contact';
import imageUrl from './images/rws_logo_darkblue_image.png';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import './custom.css';

library.add(fab, fas, far);

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
    document.cookie = "prevRoute=" + window.location.pathname;
  }
  static displayName = App.name;

  toggleLoader = () => {
    setTimeout(function (callback) {
      callback()
    }, 2000, this.toggleLoading);
  }

  toggleLoading = () => {
    this.setState({
      loading: !this.state.loading
    });
  }

  render() {
    return (
      <Layout>
        <div className={this.state.loading ? "loading-overlay fade-in" : "loading-overlay fade-out"}>
          <div className="loading-container"
            style={{ backgroundImage: `url(${imageUrl})` }}>
          </div>
        </div>
        <Route exact path='/' component={() => <Home
          toggleLoader={this.toggleLoader}
        />} />
        <Route path='/services' component={() => <Services
          toggleLoader={this.toggleLoader}
        />} />
        {/*<Route path='/about' component={() => <About
          toggleLoader={this.toggleLoader}
        />} />
        <Route path='/faq' component={() => <FAQ
          toggleLoader={this.toggleLoader}
        />} />*/}
        <Route path='/gallery' component={() => <Gallery
          toggleLoader={this.toggleLoader}
        />} />
        <Route path='/contact' component={() => <Contact
          toggleLoader={this.toggleLoader}
        />} />
      </Layout>
    );
  }
}
