import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { init, sendForm } from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RwsToast as Toast } from './RwsToast';
import { formatPhoneNumber } from './AppFunctions';
import imageUrl from '../images/owners_image-min.jpg';

const recaptchaRef = React.createRef();
const siteKey = "6LfVUt0ZAAAAANlyZQN35f8hGvf070818Ktm_e1X";

export class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticking: false,
      redirectPrev: false,
      showSuccess: false,
      showError: false,
      showRecaptchaToast: false,
      name: '',
      email: '',
      phone: '',
      message: ''
    }
    document.cookie = "prevRoute=" + window.location.pathname;
  }
  static displayName = Contact.name;

  onScrollCallback = () => {
    this.setState({ ticking: false });
    let elem = document.getElementById("pageContainer");
    if (elem.scrollTop === 0)
      this.setState({ redirectPrev: true });
  }

  onScroll = (e) => {
    if (!this.state.ticking) {
      window.requestAnimationFrame(this.onScrollCallback);
      this.setState({ ticking: true });
    }
  }

  componentDidMount() {
    window.addEventListener('load', this.props.toggleLoader);
    init("user_P85s4E563sYCcIwAVfLKW");
    var links = document.getElementsByClassName('nav-bar-item');
    Array.from(links).map(link => link.style.textDecoration = '');
    var link = Array.from(links).find(link => link.attributes.href.textContent === window.location.pathname);
    link.style.textDecoration = "underline";
  }

  onSubmit = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue) {
      sendForm('service_665anni', 'template_tlocnr7', '#contactForm')
        .then(this.successCallback, this.errorCallback);
    }
    else {
      this.toggleRecaptchaToast();
    }
  }

  toggleRecaptchaToast = () => {
    this.setState({ showRecaptchaToast: !this.state.showRecaptchaToast });
  }

  successCallback = (response) => {
    this.setState({ showSuccess: true, showRecaptchaToast: false });
    console.log('SUCCESS!', response.status, response.text);
  }

  errorCallback = (error) => {
    this.setState({ showError: true, showRecaptchaToast: false });
    console.log('FAILED...', error);
  }

  render() {
    if (this.state.redirectPrev)
      return <Redirect push to="/gallery" />;

    return (
      <div className="nav-animation-up">
        <div className="nav-animation-dark"></div>
        <div className="nav-animation-light"></div>
        <div id="pageContainer" className="page-container" style={{ backgroundColor: "gray" }} onScroll={this.onScroll}>
          <div className="row">
            <div className="content-container-white col">
              {this.state.showSuccess === false && this.state.showError === false &&
                <form id="contactForm"
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.onSubmit();
                  }}
                >
                  <h4 className="font-weight-bold pb-2">CONTACT US</h4>
                  <div className="row pb-3">
                    <div className="col">
                      <label htmlFor="contact_name">Full Name</label>
                      <input
                        id="contact_name"
                        name="contact_name"
                        value={this.state.name}
                        type="text"
                        className="form-control"
                        placeholder="Enter full name..."
                        onChange={(e) => {
                          this.setState({ name: e.target.value });
                        }}
                        maxLength="100"
                        required
                      />
                    </div>
                  </div>
                  <div className="row pb-3">
                    <div className="col">
                      <label htmlFor="contact_email">Email</label>
                      <input
                        id="contact_email"
                        name="contact_email"
                        value={this.state.email}
                        type="email"
                        className="form-control"
                        placeholder="Enter email..."
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                        maxLength="100"
                        required
                      />
                    </div>
                  </div>
                  <div className="row pb-3">
                    <div className="col">
                      <label htmlFor="contact_phone">Phone</label>
                      <input
                        id="contact_phone"
                        name="contact_phone"
                        value={this.state.phone}
                        type="phone"
                        className="form-control"
                        placeholder="##########"
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        onChange={(e) => {
                          this.setState({ phone: formatPhoneNumber(e.target.value) });
                        }}
                        maxLength="12"
                        required
                      />
                    </div>
                  </div>
                  <div className="row pb-3">
                    <div className="col">
                      <label htmlFor="message">Message</label>
                      <textarea
                        id="message"
                        name="message"
                        value={this.state.message}
                        className="form-control"
                        placeholder="Enter message..."
                        style={{ width: '100%', height: '150px' }}
                        onChange={(e) => {
                          this.setState({ message: e.target.value });
                        }}
                        maxLength="100000"
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col pb-3">
                      <ReCAPTCHA
                        style={{ display: "block" }}
                        ref={recaptchaRef}
                        sitekey={siteKey}
                      />
                    </div>
                    <div className="col-auto">
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                  </div>
                </form>
              }
              {this.state.showSuccess &&
                <div className="alert alert-success" role="alert">
                  <p>
                    <FontAwesomeIcon
                      style={{
                        color: "inherit",
                        width: "2em",
                        height: "2em",
                        cursor: "pointer",
                        marginRight: "0.5em"
                      }}
                      title="Error"
                      icon={["far", "share-square"]}
                    />
                    Your message has been sent.  Someone will be contacting you shortly.
                  </p>
                </div>
              }
              {this.state.showError &&
                <div className="alert alert-danger" role="alert">
                  <p>
                    <FontAwesomeIcon
                      style={{
                        color: "inherit",
                        width: "2em",
                        height: "2em",
                        cursor: "pointer",
                        marginRight: "0.5em"
                      }}
                      title="Error"
                      icon={["fas", "exclamation-triangle"]}
                    />
                    There was an error sending your message.  Please contact us directly by phone.
                  </p>
                </div>
              }
            </div>
            <div className="col-lg col-md col-sm-auto">
              <div className="content-container font-weight-bold"
                style={{
                  margin: "0.5em",
                  color: "#E5AD56",
                  backgroundColor: "#1B3149",
                  overflow: "auto"
                }}>
                <img src={imageUrl} alt="Co-Owners" className="coowner-image mr-4" />
                <p>
                  Our mission is to perform for our customers the highest level of quality construction services
                  at fair and market competitive prices.
                </p>
                <p>
                  Our pledge is to establish lasting relationships with our customers by exceeding their expectations
                  and gaining their trust through exceptional performance.
                </p>
              </div>
              <div className="content-container font-weight-bold" style={{ color: "#E5AD56", backgroundColor: "#1B3149" }}>
                <p>
                  <FontAwesomeIcon
                    style={{
                      color: "inherit",
                      width: "1.5em",
                      height: "1.5em",
                      cursor: "pointer",
                      marginRight: "0.5em"
                    }}
                    title="Error"
                    icon={["fas", "id-card"]}
                  />
                  Chris Feldhausen (Co-Owner/Operator)
                </p>
                <p>
                  Cell: <a className="contact-link" href="tel:785-331-7389">785-331-7389</a><br />
                  Email: <a className="contact-link" href="mailto:chrisfeldhausen@gmail.com">chrisfeldhausen@gmail.com</a>
                </p>
              </div>
              <div className="content-container font-weight-bold" style={{ color: "#E5AD56", backgroundColor: "#1B3149" }}>
                <p>
                  <FontAwesomeIcon
                    style={{
                      color: "inherit",
                      width: "1.5em",
                      height: "1.5em",
                      cursor: "pointer",
                      marginRight: "0.5em"
                    }}
                    title="Error"
                    icon={["fas", "id-card"]}
                  />
                  Jarrod Konecny (Co-Owner/Operator)
                </p>
                <p>
                  Cell: <a className="contact-link" href="tel:785-230-4357">785-230-4357</a><br />
                  Email: <a className="contact-link" href="mailto:jarrod.konecny@gmail.com">jarrod.konecny@gmail.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        {this.state.showRecaptchaToast &&
          <Toast
            alertType="warning"
            title="reCAPTCHA REQUIRED"
            message="Please verify your identity using the reCAPTCHA!"
            onClose={this.toggleRecaptchaToast}
          />
        }
      </div>
    );
  }
}
