export function isNullOrUndefined(val) {
    if (val === null || val === "null" || typeof val === "undefined")
        return true;
    return false;
}

export function isNullOrEmpty(val) {
    if (val === null || val === "null" || val === "")
        return true;
    return false;
}

export function formatPhoneNumber(phoneNumber) {
  if (isNullOrUndefined(phoneNumber) || isNullOrEmpty(phoneNumber))
    return "";

  let numbers = "0123456789";
  let cleanPhoneNumber = "";
  let formattedPhoneNumber = "";

  for (var i = 0; i < phoneNumber.length; i++) {
    if (numbers.indexOf(phoneNumber[i]) !== -1)
      cleanPhoneNumber += phoneNumber[i];
  }

  if (cleanPhoneNumber.length > 10)
    cleanPhoneNumber = cleanPhoneNumber.substring(0, 10);

  for (i = 0; i < cleanPhoneNumber.length; i++) {
    if (i === 3 || i === 6)
      formattedPhoneNumber += "-";
    formattedPhoneNumber += cleanPhoneNumber[i];
  }

  return formattedPhoneNumber;
}