import React, { Component } from 'react';
import Toast from 'react-bootstrap/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class RwsToast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title || "Toast Message",
      altText: props.altText || "",
      message: props.message || "",
      onClose: props.onClose || function () { return false; },
      toastClass: this.props.alertType === "error" ? "bg-danger text-danger" :
        this.props.alertType === "warning" ? "bg-warning text-warning" :
        this.props.alertType === "success" ? "bg-success text-success" : "bg-info text-info",
      titleClass: this.props.alertType === "error" ? "mr-auto text-danger" :
        this.props.alertType === "warning" ? "mr-auto text-warning" :
        this.props.alertType === "success" ? "mr-auto text-success" : "mr-auto text-info",
      bodyStyle: this.props.alertType === "error" ? { backgroundColor: "#FAE1E3" } :
        this.props.alertType === "warning" ? { backgroundColor: "#FFF6DA" } :
        this.props.alertType === "success" ? { backgroundColor: "#DFF2E3" } : { backgroundColor: "#DCF1F4" },
      iconImage: this.props.alertType === "error" ? ["fas", "exclamation-triangle"] :
        this.props.alertType === "warning" ? ["fas", "exclamation-triangle"] :
        this.props.alertType === "success" ? ["fas", "check-circle"] : ["fas", "info-circle"],
      iconColor: this.props.alertType === "error" ? "#DC3545" :
        this.props.alertType === "warning" ? "#FFC107" :
        this.props.alertType === "success" ? "#28A745" : "#17A2B8",
      iconText: this.props.alertType === "error" ? "Error" :
        this.props.alertType === "warning" ? "Warning" :
        this.props.alertType === "success" ? "Success" : "Info"
    }
  }

  render() {
    return (
      <div style={{
        position: "fixed",
        bottom: "1em",
        right: "1em"
      }}>
        <Toast className={this.state.toastClass} onClose={this.props.onClose}>
          <Toast.Header>
            <FontAwesomeIcon
              style={{
                color: this.state.iconColor,
                width: "1em",
                height: "1em",
                marginRight: "0.5em"
              }}
              title={this.state.iconText}
              icon={this.state.iconImage}
            />
            <strong className={this.state.titleClass}>{this.state.title}</strong>
            <small>{this.state.altText}</small>
          </Toast.Header>
          <Toast.Body style={this.state.bodyStyle}>{this.state.message}</Toast.Body>
        </Toast>
      </div>
    );
  }
}
