import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import downspoutUrl from '../images/20200923_080847-min.jpg';
import frenchDrainUrl from '../images/20200904_091931-min.jpg';
import yardRepairUrl from '../images/20201015_150907-min.jpg';
import trenchingUrl from '../images/IMG_0795-min.jpg';
import yardGradingUrl from '../images/20200810_154841-min.jpg';
import landscapingUrl from '../images/20200831_113310-min.jpg';
import drainageUrl from '../images/20200923_080737-min.jpg';
import trafficControlUrl from '../images/traffic_control-min.jpg';
import rentSignageUrl from '../images/traffic_control_signs-min.jpg';
import lawnAerationUrl from '../images/lawn_aeration-min.jpeg';
import sodInstallUrl from '../images/20200930_124448-min.jpg';
import overseedingUrl from '../images/lawn_overseeding-min.jpg';
import snowRemovalUrl from '../images/snow_removal-min.jpg';
import hardscapesUrl from '../images/20201015_161922-min.jpg';
import mulchInstallUrl from '../images/mulch_install-min.jpg';
import rockInstallationUrl from '../images/20200916_123207-min.jpg';

export class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticking: false,
      redirectNext: false,
      redirectPrev: false,
      navUp: document.cookie.split("prevRoute=")[1] === "/"
    }
    document.cookie = "prevRoute=" + window.location.pathname;
  }
  static displayName = Services.name;

  onScrollCallback = () => {
    this.setState({ ticking: false });
    let elem = document.getElementById("pageContainer");
    if (elem.scrollTop + elem.clientHeight >= elem.scrollHeight)
      this.setState({ redirectNext: true });
    else if (elem.scrollTop === 0)
      this.setState({ redirectPrev: true });
  }

  onScroll = (e) => {
    if (!this.state.ticking) {
      window.requestAnimationFrame(this.onScrollCallback);
      this.setState({ ticking: true });
    }
  }

  componentDidMount() {
    window.addEventListener('load', this.props.toggleLoader);
    var links = document.getElementsByClassName('nav-bar-item');
    Array.from(links).map(link => link.style.textDecoration = '');
    var link = Array.from(links).find(link => link.attributes.href.textContent === window.location.pathname);
    link.style.textDecoration = "underline";
  }

  contentHtml = () => {
    return (
      <div id="pageContainer" className="page-container" style={{ backgroundColor: "gray" }} onScroll={this.onScroll}>
        <div className="content-container-noborder thumbnail-container">
          <div className="row">
            <div className="col-lg-4 col-md col-sm align-self-center">
              Burying Downspouts
              <FontAwesomeIcon
                style={{
                  color: "#E5AD56",
                  width: "1.em",
                  height: "1.em",
                  cursor: "pointer",
                  position: "absolute",
                  bottom: "0.5em",
                  right: "0.5em"
                }}
                icon={["fas", "info-circle"]}
              />
              <img src={downspoutUrl} alt="Downspout" />
            </div>
            <div className="col-lg-4 col-md col-sm align-self-center">
              Installing French Drains
              <FontAwesomeIcon
                style={{
                  color: "#E5AD56",
                  width: "1.em",
                  height: "1.em",
                  cursor: "pointer",
                  position: "absolute",
                  bottom: "0.5em",
                  right: "0.5em"
                }}
                icon={["fas", "info-circle"]}
              />
              <img src={frenchDrainUrl} alt="French Drain" />
            </div>
            <div className="col-lg-4 col-md col-sm align-self-center">
              Rut Repairs / Yard Restoration
              <FontAwesomeIcon
                style={{
                  color: "#E5AD56",
                  width: "1.em",
                  height: "1.em",
                  cursor: "pointer",
                  position: "absolute",
                  bottom: "0.5em",
                  right: "0.5em"
                }}
                icon={["fas", "info-circle"]}
              />
              <img src={yardRepairUrl} alt="Yard Repair" />
            </div>
            <div className="w-100"></div>
            <div className="col-lg-4 col-md col-sm align-self-center">
              Trenching
              <FontAwesomeIcon
                style={{
                  color: "#E5AD56",
                  width: "1.em",
                  height: "1.em",
                  cursor: "pointer",
                  position: "absolute",
                  bottom: "0.5em",
                  right: "0.5em"
                }}
                icon={["fas", "info-circle"]}
              />
              <img src={trenchingUrl} alt="Trenching" />
            </div>
            <div className="col-lg-4 col-md col-sm align-self-center">
              Yard Grading
              <FontAwesomeIcon
                style={{
                  color: "#E5AD56",
                  width: "1.em",
                  height: "1.em",
                  cursor: "pointer",
                  position: "absolute",
                  bottom: "0.5em",
                  right: "0.5em"
                }}
                icon={["fas", "info-circle"]}
              />
              <img src={yardGradingUrl} alt="Yard Grading" />
            </div>
            <div className="col-lg-4 col-md col-sm align-self-center">
              General Landscaping / Maintenance
              <FontAwesomeIcon
                style={{
                  color: "#E5AD56",
                  width: "1.em",
                  height: "1.em",
                  cursor: "pointer",
                  position: "absolute",
                  bottom: "0.5em",
                  right: "0.5em"
                }}
                icon={["fas", "info-circle"]}
              />
              <img src={landscapingUrl} alt="Landscaping" />
            </div>
            <div className="w-100"></div>
            <div className="col-lg-4 col-md col-sm align-self-center">
              Fix Drainage Issues
              <FontAwesomeIcon
                style={{
                  color: "#E5AD56",
                  width: "1.em",
                  height: "1.em",
                  cursor: "pointer",
                  position: "absolute",
                  bottom: "0.5em",
                  right: "0.5em"
                }}
                icon={["fas", "info-circle"]}
              />
              <img src={drainageUrl} alt="Drainage" />
            </div>
            <div className="col-lg-4 col-md col-sm align-self-center">
              Short Term Temporary Traffic Control
              <FontAwesomeIcon
                style={{
                  color: "#E5AD56",
                  width: "1.em",
                  height: "1.em",
                  cursor: "pointer",
                  position: "absolute",
                  bottom: "0.5em",
                  right: "0.5em"
                }}
                icon={["fas", "info-circle"]}
              />
              <img src={trafficControlUrl} alt="Traffic Control" />
            </div>
            <div className="col-lg-4 col-md col-sm align-self-center">
              Rent Traffic Control Signs
              <FontAwesomeIcon
                style={{
                  color: "#E5AD56",
                  width: "1.em",
                  height: "1.em",
                  cursor: "pointer",
                  position: "absolute",
                  bottom: "0.5em",
                  right: "0.5em"
                }}
                icon={["fas", "info-circle"]}
              />
              <img src={rentSignageUrl} alt="Rent Traffic Signs" />
            </div>
            <div className="w-100"></div>
            <div className="col-lg-4 col-md col-sm align-self-center">
              Lawn Aeration
              <FontAwesomeIcon
                style={{
                  color: "#E5AD56",
                  width: "1.em",
                  height: "1.em",
                  cursor: "pointer",
                  position: "absolute",
                  bottom: "0.5em",
                  right: "0.5em"
                }}
                icon={["fas", "info-circle"]}
              />
              <img src={lawnAerationUrl} alt="Lawn Aeration" />
            </div>
            <div className="col-lg-4 col-md col-sm align-self-center">
              Sod Installation
              <FontAwesomeIcon
                style={{
                  color: "#E5AD56",
                  width: "1.em",
                  height: "1.em",
                  cursor: "pointer",
                  position: "absolute",
                  bottom: "0.5em",
                  right: "0.5em"
                }}
                icon={["fas", "info-circle"]}
              />
              <img src={sodInstallUrl} alt="Sod Installation" />
            </div>
            <div className="col-lg-4 col-md col-sm align-self-center">
              Over-Seeding
              <FontAwesomeIcon
                style={{
                  color: "#E5AD56",
                  width: "1.em",
                  height: "1.em",
                  cursor: "pointer",
                  position: "absolute",
                  bottom: "0.5em",
                  right: "0.5em"
                }}
                icon={["fas", "info-circle"]}
              />
              <img src={overseedingUrl} alt="Over-Seeding" />
            </div>
            <div className="w-100"></div>
            <div className="col-lg-4 col-md col-sm align-self-center">
              Residential Snow Removal
              <FontAwesomeIcon
                style={{
                  color: "#E5AD56",
                  width: "1.em",
                  height: "1.em",
                  cursor: "pointer",
                  position: "absolute",
                  bottom: "0.5em",
                  right: "0.5em"
                }}
                icon={["fas", "info-circle"]}
              />
              <img src={snowRemovalUrl} alt="Snow Removal" />
            </div>
            <div className="col-lg-4 col-md col-sm align-self-center">
              Hardscapes
              <FontAwesomeIcon
                style={{
                  color: "#E5AD56",
                  width: "1.em",
                  height: "1.em",
                  cursor: "pointer",
                  position: "absolute",
                  bottom: "0.5em",
                  right: "0.5em"
                }}
                icon={["fas", "info-circle"]}
              />
              <img src={hardscapesUrl} alt="Hardscapes" />
            </div>
            <div className="col-lg-4 col-md col-sm align-self-center">
              Mulch
              <FontAwesomeIcon
                style={{
                  color: "#E5AD56",
                  width: "1.em",
                  height: "1.em",
                  cursor: "pointer",
                  position: "absolute",
                  bottom: "0.5em",
                  right: "0.5em"
                }}
                icon={["fas", "info-circle"]}
              />
              <img src={mulchInstallUrl} alt="Mulch" />
            </div>
            <div className="w-100"></div>
            <div className="col-lg-4 col-md-4 col-sm align-self-center">
              Rock Installation
              <FontAwesomeIcon
                style={{
                  color: "#E5AD56",
                  width: "1.em",
                  height: "1.em",
                  cursor: "pointer",
                  position: "absolute",
                  bottom: "0.5em",
                  right: "0.5em"
                }}
                icon={["fas", "info-circle"]}
              />
              <img src={rockInstallationUrl} alt="Rock Installation" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.redirectNext)
      return <Redirect push to="/gallery" />;
    else if (this.state.redirectPrev)
      return <Redirect push to="/" />;

    return (
      <div>
        {this.state.navUp &&
          <div className="nav-animation-up">
            <div className="nav-animation-dark"></div>
            <div className="nav-animation-light"></div>
            {this.contentHtml()}
          </div>
        }
        {!this.state.navUp &&
          <div className="nav-animation-down">
            {this.contentHtml()}
            <div className="nav-animation-dark"></div>
            <div className="nav-animation-light"></div>
          </div>
        }
      </div>
    );
  }
}
