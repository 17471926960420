import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import json from '../galleryImages.json';

const images = require.context('../gallery', true);
const sortedFiles = json.files.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1);
const imagesHtml = sortedFiles.map((file) => {
  return (
      <img key={file.path} className="gallery-photo" src={images(`${file.path}`)} alt="" />
  );
});
const col1Html = imagesHtml.slice(0, Math.floor(sortedFiles.length / 3));
const col2Html = imagesHtml.slice(Math.floor(sortedFiles.length / 3) + 1, Math.floor(sortedFiles.length / 3) * 2);
const col3Html = imagesHtml.slice((Math.floor(sortedFiles.length / 3) * 2) + 1);
const innerHtml = () => {
  return (
    <div className="row gallery-row">
      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        {col1Html}
      </div>
      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        {col2Html}
      </div>
      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        {col3Html}
      </div>
    </div>
  );
}

export class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticking: false,
      redirectNext: false,
      redirectPrev: false,
      navUp: document.cookie.split("prevRoute=")[1] === "/"
        || document.cookie.split("prevRoute=")[1] === "/services"
    }
    document.cookie = "prevRoute=" + window.location.pathname;
  }
  static displayName = Gallery.name;

  onScrollCallback = () => {
    this.setState({ ticking: false });
    let elem = document.getElementById("pageContainer");
    if (elem.scrollTop + elem.clientHeight >= elem.scrollHeight)
      this.setState({ redirectNext: true });
    else if (elem.scrollTop === 0)
      this.setState({ redirectPrev: true });
  }

  onScroll = (e) => {
    if (!this.state.ticking) {
      window.requestAnimationFrame(this.onScrollCallback);
      this.setState({ ticking: true });
    }
  }

  componentDidMount() {
    window.addEventListener('load', this.props.toggleLoader);
    var links = document.getElementsByClassName('nav-bar-item');
    Array.from(links).map(link => link.style.textDecoration = '');
    var link = Array.from(links).find(link => link.attributes.href.textContent === window.location.pathname);
    link.style.textDecoration = "underline";
  }

  contentHtml = () => {
    return (
      <div id="pageContainer" className="page-container" onScroll={this.onScroll} style={{ backgroundColor: "gray" }}>
        {innerHtml()}
      </div>
    );
  }

  render() {
    if (this.state.redirectNext)
      return <Redirect push to="/contact" />;
    else if (this.state.redirectPrev)
      return <Redirect push to="/services" />;

    return (
      <div>
        {this.state.navUp &&
          <div className="nav-animation-up">
            <div className="nav-animation-dark"></div>
            <div className="nav-animation-light"></div>
            {this.contentHtml()}
          </div>
        }
        {!this.state.navUp &&
          <div className="nav-animation-down">
            {this.contentHtml()}
            <div className="nav-animation-dark"></div>
            <div className="nav-animation-light"></div>
          </div>
        }
      </div>
    );
  }
}
