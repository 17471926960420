import React, { Component } from 'react';
import { NavbarBrand, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import imageUrl from '../images/rws_logo_orange_image.png'

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);
    let path = window.location.pathname;
    this.state = {
      collapsed: true,
      servicesActive: path === "/services" ? true : false,
      galleryActive: path === "/gallery" ? true : false,
      //aboutActive: path === "/about" ? true : false,
      //faqActive: path === "/faq" ? true : false,
      contactActive: path === "/contact" ? true : false
    };
    this.toggleNavLinks = this.toggleNavLinks.bind(this);
  }

  toggleNavLinks() {
    document.getElementsByClassName('animated-icon')[0].classList.toggle('open');
    var element = document.getElementsByClassName('navbar-links-container')[0];
    element.classList.toggle('nav-fade-in');
    element.classList.toggle('nav-fade-out');
  }

  render () {
    return (
      <header>
        <nav className="navbar fixed-top">
          {/*Logo*/}
          <NavbarBrand
            onClick={() => {
              if (document.getElementsByClassName('navbar-links-container')[0].classList.contains('nav-fade-in'))
                this.toggleNavLinks();

              this.setState({
                servicesActive: false,
                galleryActive: false,
                //aboutActive: false,
                //faqActive: false,
                contactActive: false
              })
            }}
            tag={Link} to="/"
          >
            <div style={{ display: "inline-block" }}>
              <img className="navbar-logo" src={imageUrl} alt="RWS" />
            </div>
            <div className="navbar-title">RIGHT WAY SOLUTIONS</div>
          </NavbarBrand>
          {/*Nav Menu Button*/}
          <button
            className="navbar-toggler second-button"
            type="button"
            onClick={() => {
              this.toggleNavLinks();
            }}
          >
            <div className="animated-icon"><span></span><span></span><span></span><span></span></div>
          </button>
        </nav>
        {/*Nav Menu Links*/}
        <div className="navbar-links-container nav-fade-out">
          <div className="navbar-links">
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink
                  style={this.state.servicesActive ? {textDecoration: "underline" } : { textDecoration: "none" }}
                  onClick={() => {
                    this.toggleNavLinks();
                    this.setState({
                      servicesActive: true,
                      galleryActive: false,
                      //aboutActive: false,
                      //faqActive: false,
                      contactActive: false
                    })
                  }}
                  tag={Link}
                  className="nav-bar-item"
                  to="/services"><span>SERVICES</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={this.state.aboutActive ? { textDecoration: "underline" } : { textDecoration: "none" }}
                  onClick={() => {
                    this.toggleNavLinks();
                    this.setState({
                      servicesActive: false,
                      galleryActive: true,
                      //aboutActive: false,
                      //faqActive: false,
                      contactActive: false
                    })
                  }}
                  tag={Link}
                  className="nav-bar-item"
                  to="/gallery"><span>GALLERY</span>
                </NavLink>
              </NavItem>
              {/*<NavItem>
                <NavLink
                  style={this.state.aboutActive ? { textDecoration: "underline" } : { textDecoration: "none" }}
                  onClick={() => {
                    this.toggleNavLinks();
                    this.setState({
                      servicesActive: false,
                      galleryActive: false,
                      aboutActive: true,
                      faqActive: false,
                      contactActive: false
                    })
                  }}
                  tag={Link}
                  className="nav-bar-item"
                  to="/about"><span>ABOUT</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={this.state.faqActive ? { textDecoration: "underline" } : { textDecoration: "none" }}
                  onClick={() => {
                    this.toggleNavLinks();
                    this.setState({
                      servicesActive: false,
                      galleryActive: false,
                      aboutActive: false,
                      faqActive: true,
                      contactActive: false
                    })
                  }}
                  tag={Link}
                  className="nav-bar-item"
                  to="/faq"><span>FAQ</span>
                </NavLink>
              </NavItem>*/}
              <NavItem>
                <NavLink
                  style={this.state.contactActive ? {textDecoration: "underline" } : { textDecoration: "none" }}
                  onClick={() => {
                    this.toggleNavLinks();
                    this.setState({
                      servicesActive: false,
                      galleryActive: false,
                      //aboutActive: false,
                      //faqActive: false,
                      contactActive: true
                    })
                  }}
                  tag={Link}
                  className="nav-bar-item"
                  to="/contact"><span>CONTACT</span>
                </NavLink>
              </NavItem>
            </ul>
          </div>
        </div>
      </header>
    );
  }
}
