import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { BrowserView, MobileView } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imageUrl from '../images/background_image-min.jpg';
import videoUrl from '../videos/live_background.mp4';

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticking: false,
      redirectNext: false,
      navUp: document.cookie.split("prevRoute=")[1] === "/"
    }
    document.cookie = "prevRoute=" + window.location.pathname;
  }
  static displayName = Home.name;

  onScrollCallback = () => {
    this.setState({ ticking: false });
    let elem = document.getElementById("pageContainer");
    if (elem.scrollTop + elem.clientHeight >= elem.scrollHeight)
      this.setState({ redirectNext: true });
  }

  onScroll = (e) => {
    if (!this.state.ticking) {
      window.requestAnimationFrame(this.onScrollCallback);
      this.setState({ ticking: true });
    }
  }

  componentDidMount() {
    window.addEventListener('load', this.props.toggleLoader);
    var links = document.getElementsByClassName('nav-bar-item');
    Array.from(links).map(link => link.style.textDecoration = '');
  }

  contentHtml = () => {
    return (
      <div id="pageContainer" className="page-container" onScroll={this.onScroll}>
        <FontAwesomeIcon
          style={{
            color: "#E5AD56",
            width: "2.5em",
            height: "2.5em",
            cursor: "pointer",
            position: "fixed",
            bottom: "1.5em",
            left: "1.5em"
          }}
          onClick={() => window.open('https://www.facebook.com/Right-Way-Solutions-LLC-108597594379893/', '_blank', 'noopener')}
          title="Facebook"
          icon={["fab", "facebook-square"]}
        />
      </div>
    );
  }

  render() {
    if (this.state.redirectNext)
      return <Redirect push to="/services" />;

    return (
      <div>
        <BrowserView>
          <video className="live-background" autoPlay muted loop>
            <source src={videoUrl} type="video/mp4" />
          </video>
        </BrowserView>
        <MobileView>
          <div className="static-background" style={{ backgroundImage: `url(${imageUrl})` }}></div>
        </MobileView>
        <div className="bg-overlay"></div>
        {this.state.navUp &&
          <div className="nav-animation-up">
            <div className="nav-animation-dark"></div>
            <div className="nav-animation-light"></div>
            {this.contentHtml()}
          </div >  
        }
        {!this.state.navUp &&
          <div className="nav-animation-down">
            {this.contentHtml()}
            <div className="nav-animation-dark"></div>
            <div className="nav-animation-light"></div>
          </div>
        }
      </div>
    );
  }
}
